import React from 'react'
import {Col, Form, Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import Button from '../../UI/Button'

const VideoForm = ({onSubmit, video = {}}) => {
    const {
        register,
        formState: {errors, isValid},
        handleSubmit,
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onSubmit',
        defaultValues: {
            videoId: video.id ?? false,
            title: video.title ?? '',
            desc: video.desc ?? '',
        },
    })

    return (
        <Form className="profile-edit" onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col md={6}>
                    <Form.Group controlId="formFile" className="mb-4">
                        <Form.Label>Видео файл</Form.Label>
                        <Form.Control type="file" {...register('image')} />
                    </Form.Group>
                </Col>
                <Col md={6} className="d-flex align-items-center">
                    <small>Видео в форматах mp4, avi и не более 150 мб</small>
                </Col>
                <Col md={12}>
                    <Form.Group className="mb-4">
                        <Form.Label>Название</Form.Label>
                        <Form.Control
                            placeholder="Введите название"
                            {...register('title', {maxLength: {value: 250, message: 'Максимум 250 символов'}})}
                        />
                        {errors.title && <Form.Text className="text-danger">{errors?.title?.message}</Form.Text>}
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group className="mb-4">
                        <Form.Label>Описание</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            placeholder="Введите описание"
                            {...register('desc', {
                                maxLength: {value: 10000, message: 'Максимум 10000 символов'},
                            })}
                        />
                        {errors.desc && <Form.Text className="text-danger">{errors?.desc?.message}</Form.Text>}
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group>
                <Button type="submit" className="btn-2" disabled={!isValid}>
                    {video.length > 0 ? 'Сохранить изменения' : 'Сохранить'}
                </Button>
            </Form.Group>
        </Form>
    )
}

export default VideoForm
