import React from 'react'
import Container from 'react-bootstrap/Container'
import {MetaTags} from 'react-meta-tags'

const Policy = () => {
    return (
        <main>
            <MetaTags>
                <title>{process.env.REACT_APP_SITE_NAME} — Политика конфиденциальности</title>
                <meta
                    property="title"
                    content={process.env.REACT_APP_SITE_NAME + ' — Политика конфиденциальности'}
                />
                <meta
                    property="og:title"
                    content={process.env.REACT_APP_SITE_NAME + ' — Политика конфиденциальности'}
                />
            </MetaTags>
            <Container className="pt-5">
                Настоящая политика обработки персональных данных составлена в соответствии с требованиями
                Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» (далее – Закон ПДн) и определяет
                порядок обработки персональных данных и меры по обеспечению безопасности персональных данных
                Обществом с ограниченной ответственностью «Восточная Инструментальная компания» (далее – ООО «ВИК»).
                1. ООО «ВИК» ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав
                и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на
                неприкосновенность частной жизни, личную и семейную тайну.
                <br />
                2. Настоящая Политика в отношении обработки и защиты персональных данных (далее – Политика)
                применяется ко всей информации, которую ООО «ВИК» может получить о посетителях, клиентах,
                контрагентах и т.д. (далее – Пользователь) мобильного приложения VERTON.
                <br />
                3. Настоящая Политика распространяется на отношения в области обработки и защиты персональных данных,
                возникших у ООО «ВИК» как до, так и после опубликования настоящей Политики.
                <br />
                4. Во исполнения требований ч. 2 ст. 18.1 Закона ПДн о персональных данных настоящая Политика
                публикуется в свободном доступе в мобильном приложении VERTON и на веб-сайте www.verton-tools.ru.
                <br />
                <p>2. Основные понятия, используемые в Политике</p>
                <br />
                1. Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств
                вычислительной техники.
                <br />
                2. Блокирование персональных данных – временное прекращение обработки персональных данных (за
                исключением случаев, если обработка необходима для уточнения персональных данных).
                <br />
                3. Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз
                данных, обеспечивающих их доступность в информационно- телекоммуникационной сети Интернет по сетевому
                адресу www.verton-tools.ru.
                <br />
                4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных
                данных, и обеспечивающих их обработку информационных технологий, и технических средств.
                <br />
                5. Обезличивание персональных данных — действия, в результате которых невозможно определить без
                использования дополнительной информации принадлежность персональных данных конкретному Пользователю
                или иному субъекту персональных данных.
                <br />
                6. Обработка персональных данных – любое действие (операция) или совокупность действий (операций),
                совершаемых с использованием средств автоматизации или без использования таких средств с
                персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение
                (обновление, изменение), извлечение, использование, передачу (распространение, предоставление,
                доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
                <br />
                7. Оператор – государственный орган, муниципальный орган, юридическое или физическое лицо,
                самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку
                персональных данных, а также определяющие цели обработки персональных данных, состав персональных
                данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
                <br />
                8. Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или
                определяемому Пользователю веб-сайта www.verton-tools.ru и мобильного приложения VERTON.
                <br />
                9. Пользователь – любой посетитель, клиент, контрагент и т.д. веб- сайта www.verton-tools.ru и
                мобильного приложения VERTON.
                <br />
                10. Предоставление персональных данных – действия, направленные на раскрытие персональных данных
                определенному лицу или определенному кругу лиц.
                <br />
                11. Распространение персональных данных – любые действия, направленные на раскрытие персональных
                данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными
                данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой
                информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к
                персональным данным каким-либо иным способом.
                <br />
                12. Трансграничная передача персональных данных – передача персональных данных на территорию
                иностранного государства органу власти иностранного государства, иностранному физическому или
                иностранному юридическому лицу.
                <br />
                13. Уничтожение персональных данных – любые действия, в результате которых персональные данные
                уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных
                в информационной системе персональных данных и (или) результате которых уничтожаются материальные
                носители персональных данных.
                <br />
                <p>3. ООО «ВИК» может обрабатывать следующие персональные данные Пользователя:</p>
                <br />
                1. Фамилия, имя, отчество.
                <br />
                2. Номер контактного телефона и адрес электронной почты, по которым мы можем связаться с Вами.
                <br />
                3. Адрес прописки
                <br />
                4. Адрес проживания (доставки)
                <br />
                5. Паспортные данные (серия и номер паспорта; код подразделения; дата выдачи; орган, выдавший
                паспорт; дата рождения; место рождения).
                <br />
                6. ИНН
                <br />
                7. СНИЛС
                <br />
                8. Банковские реквизиты Также на сайте происходит сбор и обработка обезличенных данных о посетителях
                (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и
                других). Вышеперечисленные данные далее по тексту настоящей Политики объединены общим понятием
                Персональные данные.
                <br />
                <p>4. Цели обработки персональных данных</p>
                <br />
                1. Цель обработки персональных данных Пользователя — предоставление доступа Пользователю к сервисам,
                информации и/или материалам, содержащимся на веб- сайте www.verton-tools.ru, в мобильном приложении
                VERTON.
                <br />
                2. Также ООО «ВИК» имеет право направлять Пользователю уведомления о новых продуктах и услугах,
                специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения
                информационных сообщений, направив ООО «ВИК» письмо на адреса местонахождения ООО «ВИК» или
                электронной почты, указанные в разделе Реквизиты ООО «ВИК» настоящей Политики, с пометкой «Отказ от
                уведомлений о новых продуктах и услугах и специальных предложениях».
                <br />
                3. Обезличенные данные Пользователей, собираемые с помощью сервисов интернет- статистики, служат для
                сбора информации о действиях Пользователей на сайте, улучшения качества веб-сайта и его содержания.
                <br />
                <p>5. Основные права и обязанности ООО «ВИК».</p>
                <br />
                1. ООО «ВИК» имеет право:
                <br />
                1. самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения
                выполнения обязанностей, предусмотренных Законом ПДн и принятыми в соответствии с ним нормативными
                правовыми актами, если иное не предусмотрено Законом ПДН или другими федеральными законами;
                <br />
                2. поручить обработку персональных данных другому лицу с согласия Пользователя, если иное не
                предусмотрено федеральным законом, на основании заключаемого с этим лицом договора. Лицо,
                осуществляющее обработку персональных данных по поручению ООО «ВИК», обязано соблюдать принципы и
                правила обработки персональных данных, предусмотренные Законом ПДн;
                <br />
                3. в случае отзыва Пользователем согласия на обработку персональных данных ООО «ВИК» вправе
                продолжить обработку персональных данных без согласия субъекта персональных данных и в срок при
                наличии оснований, указанных в Законе ПДн;
                <br />
                4. получать согласие на обработку персональных данных, разрешенных пользователем для распространения.
                <br />
                1. ООО «ВИК» обязан:
                <br />
                1. организовывать обработку персональных данных в соответствии с требованиями Закона ПДн;
                <br />
                2. отвечать на обращения и запросы Пользователя и их законных представителей в соответствии с
                требованиями Закона ПДн;
                <br />
                3. сообщать в уполномоченный орган по защите прав субъектов персональных данных (Федеральную службу
                по надзору в сфере связи, информационных технологий и массовых коммуникаций (Роскомнадзор)) по
                запросу этого органа необходимую информацию в течение 30 (тридцати) календарных дней с даты получения
                такого запроса.
                <br />
                <p>6. Основные права Пользователя.</p>
                <br />
                Пользователь имеет право:
                <br />
                1. получать информацию, касающуюся обработки его персональных данных, за исключением случаев,
                предусмотренных федеральными законами. Сведения предоставляются Пользователю ООО «ВИК» в доступной
                форме, и в них не должны содержаться персональные данные, относящиеся к другим Пользователям, за
                исключением случаев, когда имеются законные основания для раскрытия таких персональных данных.
                Перечень информации и порядок ее получения установлен Законом ПДн;
                <br />
                2. требовать от ООО «ВИК» уточнения его персональных данных, их блокирования или уничтожения в
                случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными
                или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом
                меры по защите своих прав;
                <br />
                3. выдвигать условие предварительного согласия при обработке персональных данных в целях продвижения
                на рынке товаров, работ и услуг;
                <br />
                4. обжаловать в Роскомнадзоре или в судебном порядке неправомерные действия или бездействие ООО «ВИК»
                при обработке его персональных данных;
                <br />
                5. требовать от ООО «ВИК» в любое время прекращения передачи (распространение, предоставление,
                доступ) персональных данных, разрешенных пользователем для распространения. Данное требование должно
                включать в себя фамилию, имя, отчество (при наличии), контактную информацию (номер телефона, адрес
                электронной почты или почтовый адрес) пользователя, а также перечень персональных данных, обработка
                которых подлежит прекращению. Указанные в данном требовании персональные данные могут обрабатываться
                только оператором, которому оно направлено.
                <br />
                <p>7. Согласие на обработку персональных данных</p>
                <br />
                1. Согласие на обработку персональных данных может быть выражена Пользователем путем использования
                веб-сайта www.verton-tools.ru и мобильного приложения VERTON после ознакомления с условиями настоящей
                Политики и посредством заполнения формы регистрации на веб- сайте www.verton-tools.ru или в мобильном
                приложении VERTON.
                <br />
                2. Согласие Пользователя на обработку персональных данных является конкретным, информированным и
                сознательным.
                <br />
                3. Данный сайт не предназначен для детской аудитории. Компания не осуществляет целенаправленно сбор
                персональных данных лиц, не достигших 18-ти лет.
                <br />
                <p>8. Правовые основания обработки персональных данных</p>
                <br />
                1. ООО «ВИК» обрабатывает персональные данные Пользователя только в случае их заполнения и/или
                отправки Пользователем самостоятельно через специальные формы, расположенные на веб-сайте
                www.verton-tools.ru и в мобильном приложении VERTON. Заполняя соответствующие формы и/или отправляя
                свои персональные данные ООО «ВИК», Пользователь выражает свое согласие с настоящей Политикой.
                <br />
                2. ООО «ВИК» обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в
                настройках браузера Пользователя (включено сохранение файлов «cookie» и использование технологии
                JavaScript).
                <br />
                <p>9. Меры, принимаемые ООО «ВИК» для защиты персональных данных</p>
                <br />
                Безопасность персональных данных, которые обрабатываются ООО «ВИК», обеспечивается путем реализации
                правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований
                действующего законодательства РФ в области защиты персональных данных.
                <br />
                3. ООО «ВИК» обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие
                доступ к персональным данным неуполномоченных лиц.
                <br />
                4. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам,
                за исключением случаев, связанных с исполнением действующего законодательства РФ.
                <br />
                5. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их
                самостоятельно, путем направления ООО «ВИК» уведомление на адреса, указанные в разделе Реквизиты ООО
                «ВИК» настоящей Политики, с пометкой «Актуализация персональных данных».
                <br />
                6. Срок обработки персональных данных является неограниченным. Пользователь может в любой момент
                отозвать свое согласие на обработку персональных данных, согласие на обработку персональных данных,
                разрешенных пользователем для распространения, направив ООО «ВИК» уведомление на адреса, указанные в
                разделе Реквизиты ООО «ВИК» настоящей Политики, с пометкой «Отзыв согласия на обработку персональных
                данных» и/или «Отзыв согласия на обработку персональных данных, разрешенных для распространения».
                <br />
                <p>10. Трансграничная передача персональных данных</p>
                <br />
                1. ООО «ВИК» до начала осуществления трансграничной передачи персональных данных обязан убедиться в
                том, что иностранным государством, на территорию которого предполагается осуществлять передачу
                персональных данных, обеспечивается надежная защита прав Пользователей, как субъектов персональных
                данных.
                <br />
                2. Трансграничная передача персональных данных на территории иностранных государств, не отвечающих
                вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме
                субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения
                договора, стороной которого является субъект персональных данных.
                <br />
                <p>11. Изменение настоящей Политики</p>
                <br />
                1. ООО «ВИК» оставляет за собой право вносить изменения в настоящую Политику. На Пользователе лежит
                обязанность при каждом использовании веб-сайта www.verton-tools.ru знакомиться с текстом размещенной
                Политики.
                <br />
                2. Новая редакция Политики вступает в силу с момента ее размещения в соответствующем разделе
                веб-сайта www.verton-tools.ru. Продолжение пользования веб-сайтом www.verton-tools.ru или его
                сервисами после публикации новой редакции Политики означает принятие Политики и ее условий
                Пользователем. В случае несогласия с условиями Политики Пользователь должен незамедлительно
                прекратить использование веб-сайта www.verton-tools.ru и его сервисов.
                <br />
                <p>12. Заключительные положения</p>
                <br />
                1. Контроль за исполнением требований настоящей Политики осуществляется уполномоченным лицом,
                ответственным за организацию обработки персональных данных у ООО «ВИК».
                <br />
                2. Ответственность за нарушение требований законодательства Российской Федерации и нормативных актов
                ООО «ВИК» в сфере обработки и защиты персональных данных определяется в соответствии с
                законодательством Российской Федерации.
                <br />
                3. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки и
                защиты его персональных данных, обратившись к ООО «ВИК» с помощью запроса, направленного на адреса,
                указанные в разделе Реквизиты ООО «ВИК» настоящей Политики.
                <br />
                4. Срок рассмотрения уведомлений, запросов и иных обращений, поступивших от Пользователя в адрес ООО
                «ВИК», составляет 30 (тридцать) календарных дней начиная с даты получения обращения ООО «ВИК».
                <br />
                5. Настоящая Политика действует бессрочно до замены ее новой версией.
                <br />
                6. Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу веб-сайта
                www.verton-tools.ru и в мобильном приложении VERTON.
                <br />
                <p>13. Реквизиты ООО «ВИК»:</p>
                <br />
                ООО «Восточная Инструментальная компания» <br />
                ИНН 2724203256
                <br />
                КПП 272401001
                <br />
                ОГРН 1152724005136
                <br />
                Адрес местонахождения:
                <br />
                680015, Хабаровский край, г. Хабаровск, ул. Суворова, д.73Е, <br />
                Почтовый адрес: <br />
                680015, Хабаровский край, г. Хабаровск, ул. Суворова, д.73Е, <br />
                Электронная почта: otzyv@verton-tools.ru
            </Container>
        </main>
    )
}

export default Policy
