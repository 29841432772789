import React from 'react'

const paymentText = (value) => {
    let data = {
        card: 'Банковской картой',
        cash: 'Наличными',
        online: 'Онлайн',
        ip: 'Расчетный счет',
    }
    return data[value] ?? data['card']
}
const deliveryText = (value) => {
    let data = {
        0: { text: 'Принято', color: 'text-white' },
        1: { text: 'Выполнено', color: 'text-success' },
        2: { text: 'Отменено', color: 'text-danger' },
    }
    let info = data[value] ?? data[0]
    return <span className={info.color}>{info.text}</span>
}

export { paymentText, deliveryText }
