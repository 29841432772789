import { apiRoutes } from '../config/api'
import { $authApi } from './index'

const getPlaix = async () => {
    const data = await $host.get('https://api.plaix.ru/?getLink')
    if (data) {
        return data
    } else {
        return false
    }
}

const getEprCategories = async () => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_EPR_CATEGORIES_GET)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const getEprProducts = async () => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_EPR_PRODUCTS_GET)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const createEprOrder = async (order) => {
    try {
        const response = await $authApi.post(apiRoutes.ADMIN_EPR_ORDER_CREATE, order)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        throw error
    }
}
const getCategories = async (page, limit) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_CATEGORIES_GET, {
            params: {
                page,
                limit,
            },
        })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const getSaveExcel = async (page, limit) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_EXCEL_GET, {
            responseType: 'blob',
        })

        if (response && response.status === 200) {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'file.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            return response.data
        }
    } catch (error) {
        return error
    }
}
const getCategory = async (id) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_CATEGORY_GET, {
            params: {
                id,
            },
        })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const editCategory = async (category) => {
    try {
        const response = await $authApi.post(apiRoutes.ADMIN_CATEGORY_EDIT, category)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const deleteCategory = async (id) => {
    try {
        const response = await $authApi.delete(apiRoutes.ADMIN_CATEGORY_DELETE, { data: { id } })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const createCategory = async (category) => {
    try {
        const response = await $authApi.post(apiRoutes.ADMIN_CATEGORY_CREATE, category)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}

const getProducts = async (page, limit) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_PRODUCTS_GET, {
            params: {
                page,
                limit,
            },
        })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const getProduct = async (id) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_PRODUCT_GET, {
            params: {
                id,
            },
        })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const editProduct = async (product) => {
    try {
        const formData = new FormData()
        for (var key in product) {
            if (key == 'images') {
                formData.append(key, product[key][0])
            } else {
                formData.append(key, product[key])
            }
        }
        const response = await $authApi.post(apiRoutes.ADMIN_PRODUCT_EDIT, formData)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const deleteProduct = async (id) => {
    try {
        const response = await $authApi.delete(apiRoutes.ADMIN_PRODUCT_DELETE, { data: { id } })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const createProduct = async (product) => {
    try {
        const formData = new FormData()
        for (var key in product) {
            if (key == 'images') {
                formData.append(key, product[key][0])
            } else {
                formData.append(key, product[key])
            }
        }

        const response = await $authApi.post(apiRoutes.ADMIN_PRODUCT_CREATE, formData)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}

const getOrders = async (page, limit, userId) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_ORDERS_GET, {
            params: {
                page,
                limit,
                userId
            },
        })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const getOrder = async (id) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_ORDER_GET, {
            params: {
                id,
            },
        })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const editOrder = async (order) => {
    try {
        const response = await $authApi.post(apiRoutes.ADMIN_ORDER_EDIT, order)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const deleteOrder = async (id) => {
    try {
        const response = await $authApi.delete(apiRoutes.ADMIN_ORDER_DELETE, { data: { id } })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}

const getSales = async (page, limit) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_SALES_GET, {
            params: {
                page,
                limit,
            },
        })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const getSale = async (id) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_SALE_GET, {
            params: {
                id,
            },
        })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const editSale = async (sale) => {
    try {
        const formData = new FormData()
        for (var key in sale) {
            if (key == 'image') {
                formData.append(key, sale[key][0])
            } else {
                formData.append(key, sale[key])
            }
        }

        const response = await $authApi.post(apiRoutes.ADMIN_SALE_EDIT, formData)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const deleteSale = async (id) => {
    try {
        const response = await $authApi.delete(apiRoutes.ADMIN_SALE_DELETE, { data: { id } })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const createSale = async (sale) => {
    try {
        const formData = new FormData()
        for (var key in sale) {
            if (key == 'image') {
                formData.append(key, sale[key][0])
            } else {
                formData.append(key, sale[key])
            }
        }

        const response = await $authApi.post(apiRoutes.ADMIN_SALE_CREATE, formData)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}



const getVideos = async (page, limit) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_VIDEOS_GET, {
            params: {
                page,
                limit,
            },
        })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const getVideo = async (id) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_VIDEO_GET, {
            params: {
                id,
            },
        })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const editVideo = async (video) => {
    try {
        const formData = new FormData()
        for (var key in video) {
            if (key == 'video') {
                formData.append(key, sale[key][0])
            } else {
                formData.append(key, sale[key])
            }
        }

        const response = await $authApi.post(apiRoutes.ADMIN_SALE_EDIT, formData)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const deleteVideo = async (id) => {
    try {
        const response = await $authApi.delete(apiRoutes.ADMIN_VIDEO_DELETE, { data: { id } })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const createVideo = async (video) => {
    try {
        const formData = new FormData()
        for (var key in video) {
            if (key == 'image') {
                formData.append(key, video[key][0])
            } else {
                formData.append(key, video[key])
            }
        }

        const response = await $authApi.post(apiRoutes.ADMIN_VIDEO_CREATE, formData)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}


const getShops = async (page, limit) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_SHOPS_GET, {
            params: {
                page,
                limit,
            },
        })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const getShop = async (id) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_SHOP_GET, {
            params: {
                id,
            },
        })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const editShop = async (shop) => {
    try {
        const formData = new FormData()
        for (var key in shop) {
            if (key == 'image') {
                formData.append(key, shop[key][0])
            } else {
                formData.append(key, shop[key])
            }
        }

        const response = await $authApi.post(apiRoutes.ADMIN_SHOP_EDIT, formData)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const deleteShop = async (id) => {
    try {
        const response = await $authApi.delete(apiRoutes.ADMIN_SHOP_DELETE, { data: { id } })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const createShop = async (shop) => {
    try {
        const formData = new FormData()
        for (var key in shop) {
            if (key == 'image') {
                formData.append(key, shop[key][0])
            } else {
                formData.append(key, shop[key])
            }
        }

        const response = await $authApi.post(apiRoutes.ADMIN_SHOP_CREATE, formData)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}

const getNotifications = async (page, limit) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_NOTIFICATIONS_GET, {
            params: {
                page,
                limit,
            },
        })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const createNotification = async (notification) => {
    try {
        const response = await $authApi.post(apiRoutes.ADMIN_NOTIFICATION_CREATE, notification)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const deleteNotification = async (id) => {
    try {
        const response = await $authApi.delete(apiRoutes.ADMIN_NOTIFICATION_DELETE, { data: { id } })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}

const getUsers = async (page, limit) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_USERS_GET, {
            params: {
                page,
                limit,
            },
        })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const getUser = async (id) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_USER_GET, {
            params: {
                id,
            },
        })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const editUser = async (user) => {
    try {
        const response = await $authApi.post(apiRoutes.ADMIN_USER_EDIT, user)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const deleteUser = async (id) => {
    try {
        const response = await $authApi.delete(apiRoutes.ADMIN_USER_DELETE, { data: { id } })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}

const createMark = async (mark) => {
    try {
        const response = await $authApi.post(apiRoutes.ADMIN_MARK_CREATE, mark)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const getMarks = async (page, limit) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_MARKS_GET, {
            params: {
                page,
                limit,
            },
        })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const getMark = async (id) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_MARK_GET, {
            params: {
                id,
            },
        })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const editMark = async (mark) => {
    try {
        const response = await $authApi.post(apiRoutes.ADMIN_MARK_EDIT, mark)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const deleteMark = async (id) => {
    try {
        const response = await $authApi.delete(apiRoutes.ADMIN_MARK_DELETE, { data: { id } })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}

const getTransactions = async (page, limit) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_TRANSACTIONS_GET, {
            params: {
                page,
                limit,
            },
        })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const getTransaction = async (id) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_TRANSACTION_GET, {
            params: {
                id,
            },
        })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const editTransaction = async (data) => {
    try {
        const response = await $authApi.post(apiRoutes.ADMIN_TRANSACTION_EDIT, data)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const deleteTransaction = async (id) => {
    try {
        const response = await $authApi.delete(apiRoutes.ADMIN_TRANSACTION_DELETE, { data: { id } })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}

const getComplaints = async (page, limit, userId) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_COMPLAINTS_GET, {
            params: {
                page,
                limit,
                userId
            },
        })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const getComplain = async (id) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_COMPLAIN_GET, {
            params: {
                id,
            },
        })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const editComplain = async (complain) => {
    try {
        const response = await $authApi.post(apiRoutes.ADMIN_COMPLAIN_EDIT, complain)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const deleteComplain = async (id) => {
    try {
        const response = await $authApi.delete(apiRoutes.ADMIN_COMPLAIN_DELETE, { data: { id } })

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const getStatistic = async () => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_STATISTIC_GET)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}

const updateSite = async (data) => {
    try {
        const response = await $authApi.post(apiRoutes.ADMIN_SITE_UPDATE, data)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
const getSite = async (data) => {
    try {
        const response = await $authApi.get(apiRoutes.ADMIN_SITE_GET, data)

        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        return error
    }
}
export {
    getEprCategories,
    getEprProducts,
    createEprOrder,
    getCategories,
    getCategory,
    editCategory,
    createCategory,
    deleteCategory,

    getProducts,
    getProduct,
    editProduct,
    createProduct,
    deleteProduct,

    getOrders,
    getOrder,
    editOrder,
    deleteOrder,

    getSales,
    getSale,
    editSale,
    createSale,
    deleteSale,

    getVideos,
    getVideo,
    editVideo,
    createVideo,
    deleteVideo,

    getUsers,
    getUser,
    editUser,
    deleteUser,

    getStatistic,

    createNotification,
    deleteNotification,
    getNotifications,

    createMark,
    getMarks,
    getMark,
    editMark,
    deleteMark,

    getTransactions,
    getTransaction,
    editTransaction,
    deleteTransaction,

    getComplaints,
    getComplain,
    editComplain,
    deleteComplain,

    updateSite,
    getSite,
    getSaveExcel,

    getShops,
    getShop,
    editShop,
    createShop,
    deleteShop,
}
