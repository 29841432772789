import React, {useCallback, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {editTransaction, getTransaction} from '../../../services/admin'
import {dispatchAlert, dispatchApiErrorAlert} from '../../../helpers/alert'
import Loader from '../../../components/UI/Loader'
import Info from '../../../components/UI/Info'
import {apiResponseMessages} from '../../../config/api'
import TransactionForm from '../../../components/forms/admin/TransactionForm'

const AdminTransactionEdit = () => {
    const {transactionId} = useParams()
    const [transaction, setTransaction] = useState({
        isLoaded: false,
        error: null,
        data: {},
    })

    useEffect(() => {
        getTransaction(transactionId)
            .then(
                (res) =>
                    res &&
                    setTransaction((prev) => ({
                        ...prev,
                        isLoaded: true,
                        data: res.transaction,
                    }))
            )
            .catch((error) => error && setTransaction((prev) => ({...prev, isLoaded: true, error})))
    }, [])

    const onSubmit = useCallback((data) => {
        editTransaction(data)
            .then((res) => {
                if (res.type == 'SUCCESS') {
                    dispatchAlert('success', 'Транзакция успешно изменена')
                }
            })
            .catch((error) => {
                dispatchApiErrorAlert(error)
            })
    }, [])

    if (!transaction.isLoaded) {
        return <Loader full />
    }
    if (!transaction) {
        return (
            <Info className="d-flex flex-column align-items-center justify-content-center account-info">
                Такой транзакции нет
            </Info>
        )
    }

    return (
        <section className="profile">
            <h1>Редактировать транзакцию</h1>
            <TransactionForm onSubmit={onSubmit} transaction={transaction.data} />
        </section>
    )
}

export default AdminTransactionEdit
