import React, {useCallback, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import VideoForm from '../../../components/forms/admin/VideoForm'
import Info from '../../../components/UI/Info'
import Loader from '../../../components/UI/Loader'
import {apiResponseMessages} from '../../../config/api'
import {dispatchAlert, dispatchApiErrorAlert} from '../../../helpers/alert'
import {editVideo, getVideo} from '../../../services/admin'

const EditVideo = () => {
    const {videoId} = useParams()
    const [video, setVideo] = useState({
        isLoaded: false,
        error: null,
        data: {},
    })

    useEffect(() => {
        getVideo(videoId)
            .then(
                (res) =>
                    res &&
                    setVideo((prev) => ({
                        ...prev,
                        isLoaded: true,
                        data: res.video,
                    }))
            )
            .catch((error) => error && setVideo((prev) => ({...prev, isLoaded: true, error})))
    }, [])

    const onSubmit = useCallback((data) => {
        editVideo(data)
            .then((res) => {
                if (res.type == 'SUCCESS') {
                    dispatchAlert('success', apiResponseMessages.ADMIN_SALE_EDIT)
                }
            })
            .catch((error) => {
                dispatchApiErrorAlert(error)
            })
    }, [])

    if (!video.isLoaded) {
        return <Loader full />
    }
    if (!video.data) {
        return (
            <Info className="d-flex flex-column align-items-center justify-content-center account-info">
                Такой акции нет
            </Info>
        )
    }

    return (
        <section className="profile">
            <h1>Редактировать акцию</h1>
            <VideoForm onSubmit={onSubmit} video={video.data} />
        </section>
    )
}

export default EditVideo
