import React from 'react'
import {Route, Routes} from 'react-router-dom'
import AdminLayout from '../layouts/AdminLayout'
import NotFound from '../pages/NotFound'
import Admin from '../pages/admin'

import AdminOrders from '../pages/admin/order'
import AdminOrderEdit from '../pages/admin/order/Edit'

import AdminNotifications from '../pages/admin/notification'
import AdminNotificationCreate from '../pages/admin/notification/Create'

import AdminCategories from '../pages/admin/category'
import AdminCategoryEdit from '../pages/admin/category/Edit'
import AdminCategoryCreate from '../pages/admin/category/Create'

import AdminProducts from '../pages/admin/product'
import AdminProductEdit from '../pages/admin/product/Edit'
import AdminProductCreate from '../pages/admin/product/Create'

import AdminUsers from '../pages/admin/user'
import AdminUserEdit from '../pages/admin/user/Edit'

import AdminSales from '../pages/admin/sale'
import AdminSaleEdit from '../pages/admin/sale/Edit'
import AdminSaleCreate from '../pages/admin/sale/Create'

import AdminVideos from '../pages/admin/video'
import AdminVideoEdit from '../pages/admin/video/Edit'
import AdminVideoCreate from '../pages/admin/video/Create'

import AdminShops from '../pages/admin/shop'
import AdminShopEdit from '../pages/admin/shop/Edit'
import AdminShopCreate from '../pages/admin/shop/Create'

import AdminTransactions from '../pages/admin/transaction'
import AdminTransactionEdit from '../pages/admin/transaction/Edit'
import AdminTransactionCreate from '../pages/admin/transaction/Create'

import AdminComplaints from '../pages/admin/complaint'

const AdminRounter = () => {
    return (
        <Routes>
            <Route path="/" element={<AdminLayout />}>
                <Route index element={<Admin />} />

                <Route path="orders" element={<AdminOrders />} />
                <Route path="order/:orderId" element={<AdminOrderEdit />} />

                <Route path="categories" element={<AdminCategories />} />
                <Route path="category/:categoryId" element={<AdminCategoryEdit />} />
                <Route path="category/create" element={<AdminCategoryCreate />} />

                <Route path="products" element={<AdminProducts />} />
                <Route path="product/:productId" element={<AdminProductEdit />} />
                <Route path="product/create" element={<AdminProductCreate />} />

                <Route path="notifications" element={<AdminNotifications />} />
                <Route path="notification/create" element={<AdminNotificationCreate />} />

                <Route path="users" element={<AdminUsers />} />
                <Route path="user/:userId" element={<AdminUserEdit />} />

                <Route path="sales" element={<AdminSales />} />
                <Route path="sale/:saleId" element={<AdminSaleEdit />} />
                <Route path="sale/create" element={<AdminSaleCreate />} />

                <Route path="sales" element={<AdminSales />} />
                <Route path="sale/:saleId" element={<AdminSaleEdit />} />
                <Route path="sale/create" element={<AdminSaleCreate />} />

                <Route path="videos" element={<AdminVideos />} />
                <Route path="video/:videoId" element={<AdminVideoEdit />} />
                <Route path="video/create" element={<AdminVideoCreate />} />

                <Route path="shops" element={<AdminShops />} />
                <Route path="shop/:sopId" element={<AdminShopEdit />} />
                <Route path="shop/create" element={<AdminShopCreate />} />

                <Route path="transactions" element={<AdminTransactions />} />
                <Route path="transaction/:transactionId" element={<AdminTransactionEdit />} />
                <Route path="transaction/create" element={<AdminTransactionCreate />} />

                <Route path="complaints" element={<AdminComplaints />} />
            </Route>
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

export default AdminRounter
