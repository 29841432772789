import React, {useCallback} from 'react'
import {createVideo} from '../../../services/admin'
import VideoForm from '../../../components/forms/admin/VideoForm'
import {useNavigate} from 'react-router-dom'
import {dispatchAlert, dispatchApiErrorAlert} from '../../../helpers/alert'
import {apiResponseMessages} from '../../../config/api'

const CreateVideo = () => {
    const navigate = useNavigate()

    const onSubmit = useCallback((data) => {
        createVideo(data)
            .then((res) => {
                if (res.type == 'SUCCESS') {
                    dispatchAlert('success', apiResponseMessages.ADMIN_SALE_CREATE)
                    navigate('/admin/video')
                }
            })
            .catch((error) => {
                dispatchApiErrorAlert(error)
            })
    }, [])

    return (
        <section className="profile">
            <h1>Добавить видео</h1>
            <VideoForm onSubmit={onSubmit} />
        </section>
    )
}

export default CreateVideo
