import React, {useCallback, useEffect, useState} from 'react'
import {Col, Form, Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {MetaTags} from 'react-meta-tags'
import CustomDataTable from '../../components/CustomDataTable'
import Button from '../../components/UI/Button'
import Loader from '../../components/UI/Loader'
import {apiResponseMessages} from '../../config/api'
import {dispatchAlert, dispatchApiErrorAlert} from '../../helpers/alert'
import {getSaveExcel, getStatistic} from '../../services/admin'

const Admin = () => {
    const {
        register,
        formState: {errors, isValid},
        handleSubmit,
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onSubmit',
        defaultValues: {
            search: '',
        },
    })

    const [statistic, setStatistic] = useState({
        users: 0,
        products: 0,
        categories: 0,
        orders: 0,
        addresses: 0,
        items: [],
        isLoaded: false,
        pagination: [],
    })

    const onSaveExcel = useCallback(() => {
        getSaveExcel()
            .then((res) => {
                if (res.type == 'SUCCESS') {
                    dispatchAlert('success', apiResponseMessages.ADMIN_STATISTICK)
                }
            })
            .catch((error) => {
                dispatchApiErrorAlert(error)
            })
    }, [])

    const [limit, setLimit] = useState(10)
    const statisticColumns = [
        {
            name: 'Название',
            selector: 'title',
            sortable: true,
            width: '180px',
        },
        {
            name: 'Ф.И.О',
            selector: 'fullName',
            sortable: true,
            width: '250px',
        },
        {
            name: 'Товары',
            selector: 'countProduct',
            sortable: true,
            width: '100px',
        },
        {
            name: 'Баллы',
            selector: 'countPoint',
            sortable: true,
            width: '100px',
        },
        {
            name: 'Город',
            selector: 'city',
            sortable: true,
            width: '140px',
        },
        {
            name: 'Адрес',
            selector: 'address',
            sortable: true,
            width: '250px',
        },
    ]
    const getData = (page) => {
        getStatistic(page, limit)
            .then(
                (res) =>
                    res &&
                    setStatistic((prev) => ({
                        ...prev,
                        isLoaded: true,
                        items: res?.statistic,
                        pagination: res?.pagination,
                    }))
            )
            .finally(() => setStatistic((prev) => ({...prev, isLoaded: true})))
    }

    const handlePageChange = (page) => {
        getData(page)
    }

    const handlePerRowsChange = async (newLimit, page) => {
        setLimit(newLimit)
        getStatistic(page, newLimit)
            .then(
                (res) =>
                    res &&
                    setStatistic((prev) => ({
                        ...prev,
                        isLoaded: true,
                        items: res?.statistic,
                        pagination: res?.pagination,
                    }))
            )
            .finally(() => setStatistic((prev) => ({...prev, isLoaded: true})))
    }

    useEffect(() => {
        getData()
    }, [])

    const onSubmit = useCallback((data) => {
        // search(data).then((res) => {
        //     res &&
        //         setStatistic((prev) => ({
        //             ...prev,
        //             isLoaded: true,
        //             items: res?.statistic,
        //             pagination: res?.pagination,
        //         }))
        // })
    }, [])

    if (!statistic.isLoaded) {
        return <Loader full />
    }

    return (
        <section className="profile">
            <MetaTags>
                <title>{process.env.REACT_APP_SITE_NAME} — Статистика</title>
            </MetaTags>
            <h1>Статистика</h1>
            <Row className="align-items-center">
                <Col>
                    <Button className="btn-1" onClick={() => onSaveExcel()}>
                        Скачать в Excel
                    </Button>
                </Col>
                <Col>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Control
                            placeholder="Поиск..."
                            {...register('search', {maxLength: {value: 250, message: 'Максимум 250 символов'}})}
                        />
                        {errors.search && <Form.Text className="text-danger">{errors?.search?.message}</Form.Text>}
                    </Form>
                </Col>
            </Row>
            <CustomDataTable
                className="mt-4"
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
                noDataComponent="Нет статистики"
                columns={statisticColumns}
                data={statistic.items}
                pagination={statistic.pagination}
            />
        </section>
    )
}

export default Admin
