import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {IoClose, IoTrashOutline} from 'react-icons/io5'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Pagination} from 'swiper'
import {BASE_URL} from '../../../../config/api'
import {deliveryText} from '../../../../helpers/order'
import {customPrice} from '../../../../helpers/product'
import {deleteOrder, getOrders} from '../../../../services/admin'
import CustomDataTable from '../../../CustomDataTable'
import OrderProductItem from '../../../OrderProductItem'
import Button from '../../../UI/Button'
import Info from '../../../UI/Info'
import Loader from '../../../UI/Loader'
import CustomModal from '../../../utils/CustomModal'
import {GrEdit} from 'react-icons/gr'
import {Link} from 'react-router-dom'

const OrderTable = ({userId = ''}) => {
    const [orders, setOrders] = useState({
        isLoaded: false,
        error: null,
        items: [],
        pagination: false,
    })
    const [modalDelete, setModalDelete] = useState({
        isShow: false,
        id: false,
    })
    const [modalImage, setModalImage] = useState({
        isShow: false,
        media: false,
        index: 0,
    })
    const [limit, setLimit] = useState(10)
    const orderColumns = [
        {
            name: '#',
            width: '60px',
            sortable: true,
            selector: 'id',
        },
        {
            name: 'Статус',
            selector: 'delivery',
            sortable: true,
            cell: (row) => deliveryText(row.delivery),
        },
        {
            name: 'Фото',
            width: '90px',
            selector: 'orderProducts',
            sortable: true,
            cell: (row) => row.media.length,
        },
        {
            name: 'Товары',
            selector: 'orderProducts',
            width: '100px',
            sortable: true,
            cell: (row) => row.orderProducts.length,
        },
        {
            name: 'Время',
            selector: 'createdAt',
            sortable: true,
            cell: (row) => <small>{moment(row.createdAt).format('DD.MM.YYYY kk:mm')}</small>,
        },
        {
            name: 'Сумма',
            selector: 'total',
            width: '110px',
            sortable: true,
            cell: (row) => customPrice(row.total),
        },
        {
            name: 'Баллы',
            selector: 'point',
            width: '110px',
            sortable: true,
            cell: (row) => customPrice(row.point, false) + ' Б',
        },
        {
            selector: 'action',
            center: true,
            width: '80px',
            cell: (row) => (
                <div className="d-flex align-items-center">
                    <Link to={`/admin/order/${row.id}`} className="me-4">
                        <GrEdit size={15} color="#fff" />
                    </Link>
                    <a onClick={() => setModalDelete({isShow: !modalDelete.isShow, id: row.id})}>
                        <IoTrashOutline size={20} color="#ff5252" />
                    </a>
                </div>
            ),
        },
    ]
    const getData = async (page = 1) => {
        getOrders(page, limit, userId)
            .then(
                (res) =>
                    res &&
                    setOrders((prev) => ({
                        ...prev,
                        isLoaded: true,
                        items: res?.orders?.rows,
                        pagination: res?.pagination,
                    }))
            )
            .finally(() => setOrders((prev) => ({...prev, isLoaded: true})))
    }

    const handlePageChange = (page) => {
        getData(page)
    }

    const handlePerRowsChange = async (newLimit, page) => {
        setLimit(newLimit)
        getOrders(page, newLimit, userId)
            .then(
                (res) =>
                    res &&
                    setOrders((prev) => ({
                        ...prev,
                        isLoaded: true,
                        items: res?.orders?.rows,
                        pagination: res?.pagination,
                    }))
            )
            .finally(() => setOrders((prev) => ({...prev, isLoaded: true})))
    }

    useEffect(() => {
        getData()
    }, [])

    const clickDelete = (id) => {
        deleteOrder(id).then(() => getData())
        setModalDelete({isShow: false, id: false})
    }

    if (!orders.isLoaded) {
        return <Loader full />
    }

    if (!orders.items || orders.items.length === 0) {
        return (
            <Info className="d-flex flex-column align-items-center justify-content-center account-info">
                <h5 className="mb-4">Пока чеков нет</h5>
            </Info>
        )
    }

    return (
        <section className="orders">
            <h1 className="mb-4">Чеки</h1>
            <CustomDataTable
                columns={orderColumns}
                data={orders.items}
                pagination={orders.pagination}
                expandableRows
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
                expandableRowsComponent={({data}) => (
                    <>
                        <div className="p-3 pb-0">
                            <p className="mb-2">Фото</p>
                            {data.media &&
                                data.media.map((e, index) => (
                                    <a onClick={() => setModalImage({isShow: true, media: data.media, index})}>
                                        <img
                                            className="admin-order-photo"
                                            src={BASE_URL + '/order' + e.media.full}
                                        />
                                    </a>
                                ))}
                        </div>
                        <div className="p-3 pb-2">
                            <p>Товары</p>
                        </div>
                        {data.orderProducts && data.orderProducts.map((e) => <OrderProductItem key={e.id} {...e} />)}
                    </>
                )}
            />
            <Modal
                show={modalImage.isShow}
                onHide={(e) => setModalImage({isShow: false, media: false, index: 0})}
                className="story-modal"
            >
                <Modal.Body className="p-0">
                    <Swiper
                        className="swiper-stories-mobile"
                        modules={[Pagination]}
                        slidesPerView={1}
                        pagination={{clickable: true}}
                        onSwiper={(swiper) => swiper.slideTo(modalImage.index, 50)}
                    >
                        {modalImage.media &&
                            modalImage.media.map((e) => (
                                <SwiperSlide key={e.id}>
                                    <figure className="story-large">
                                        <img src={BASE_URL + '/order' + e.media.full} />
                                    </figure>
                                </SwiperSlide>
                            ))}
                    </Swiper>

                    <button className="close" onClick={() => setModalImage({isShow: false, media: false, index: 0})}>
                        <IoClose />
                    </button>
                </Modal.Body>
            </Modal>
            <CustomModal
                title={`Удаление ${modalDelete.id ? '#' + modalDelete.id : ''}`}
                isShow={modalDelete.isShow}
                setIsShow={(e) => setModalDelete({isShow: e, id: false})}
                footer={
                    <>
                        <Button
                            className="btn-1 me-3"
                            onClick={(e) => setModalDelete({isShow: !modalDelete.isShow, id: false})}
                        >
                            Отмена
                        </Button>
                        <Button className="btn-2" onClick={() => modalDelete.id && clickDelete(modalDelete.id)}>
                            Удалить
                        </Button>
                    </>
                }
            >
                Вы точно хотите удалить чек?
            </CustomModal>
        </section>
    )
}

export default OrderTable
