import React, {useState} from 'react'
import {
    IoCardOutline,
    IoChevronForwardOutline,
    IoCubeOutline,
    IoFlashOutline,
    IoStorefrontOutline,
    IoLayersOutline,
    IoLogOutOutline,
    IoMailOutline,
    IoPeopleOutline,
    IoReceiptOutline,
    IoBarChartOutline,
    IoVideocam,
    IoVideocamOutline,
} from 'react-icons/io5'
import {useDispatch} from 'react-redux'
import {Link, NavLink} from 'react-router-dom'
import {logout} from '../services/RTK/auth'
import Button from './UI/Button'
import CustomModal from './utils/CustomModal'

const AdminMenu = () => {
    const dispatch = useDispatch()
    const [isShowLogout, setIsShowLogout] = useState(false)
    return (
        <nav className="account-nav mb-4">
            <ul>
                <li>
                    <NavLink to="/admin" end>
                        <span className="d-flex flex-row align-items-center">
                            <IoBarChartOutline className="d-inline me-3" size={24} /> Статистика
                        </span>
                        <IoChevronForwardOutline />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="orders">
                        <span className="d-flex flex-row align-items-center">
                            <IoReceiptOutline className="d-inline me-3" size={24} /> Чеки
                        </span>
                        <IoChevronForwardOutline />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="categories">
                        <span className="d-flex flex-row align-items-center">
                            <IoLayersOutline className="d-inline me-3" size={24} /> Категории
                        </span>
                        <IoChevronForwardOutline />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="products">
                        <span className="d-flex flex-row align-items-center">
                            <IoCubeOutline className="d-inline me-3" size={24} /> Товары
                        </span>
                        <IoChevronForwardOutline />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="shops">
                        <span className="d-flex flex-row align-items-center">
                            <IoStorefrontOutline className="d-inline me-3" size={24} /> Магазины
                        </span>
                        <IoChevronForwardOutline />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="users">
                        <span className="d-flex flex-row align-items-center">
                            <IoPeopleOutline className="d-inline me-3" size={24} /> Пользователи
                        </span>
                        <IoChevronForwardOutline />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="sales">
                        <span className="d-flex flex-row align-items-center">
                            <IoFlashOutline className="d-inline me-3" size={24} /> Акции
                        </span>
                        <IoChevronForwardOutline />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="videos">
                        <span className="d-flex flex-row align-items-center">
                            <IoVideocamOutline className="d-inline me-3" size={24} /> Видео
                        </span>
                        <IoChevronForwardOutline />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="transactions">
                        <span className="d-flex flex-row align-items-center">
                            <IoCardOutline className="d-inline me-3" size={24} /> Вывод средств
                        </span>
                        <IoChevronForwardOutline />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="complaints">
                        <span className="d-flex flex-row align-items-center">
                            <IoMailOutline className="d-inline me-3" size={24} /> Тех поддержка
                        </span>
                        <IoChevronForwardOutline />
                    </NavLink>
                </li>
                <li>
                    <Link onClick={() => setIsShowLogout(!isShowLogout)}>
                        <span className="d-flex flex-row align-items-center">
                            <IoLogOutOutline className="d-inline me-3" size={24} /> Выход
                        </span>
                    </Link>
                </li>
                <CustomModal
                    isShow={isShowLogout}
                    setIsShow={setIsShowLogout}
                    title="Подтвердите действие"
                    footer={
                        <>
                            <Button type="button" className="btn-1" onClick={() => setIsShowLogout(!isShowLogout)}>
                                Отмена
                            </Button>
                            <Button type="button" className="btn-2" onClick={() => dispatch(logout())}>
                                Выйти
                            </Button>
                        </>
                    }
                >
                    Вы точно хотите выйти?
                </CustomModal>
            </ul>
        </nav>
    )
}

export default AdminMenu
