import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {GrEdit} from 'react-icons/gr'
import CustomDataTable from '../../../components/CustomDataTable'
import Loader from '../../../components/UI/Loader'
import {deleteTransaction, getTransactions} from '../../../services/admin'
import Info from '../../../components/UI/Info'
import CustomModal from '../../../components/utils/CustomModal'
import {IoTrashOutline} from 'react-icons/io5'
import Button from '../../../components/UI/Button'
import {customPrice} from '../../../helpers/product'
import moment from 'moment'
import {deliveryText} from '../../../helpers/order'

const Transactions = () => {
    const [transactions, setTransactions] = useState({
        isLoaded: false,
        error: null,
        items: [],
        pagination: false,
    })
    const [modalDelete, setModalDelete] = useState({
        isShow: false,
        id: false,
    })
    const [limit, setLimit] = useState(10)
    const transactionColumns = [
        {
            name: 'Сумма',
            selector: 'total',
            cell: (row) => customPrice(row.total),
        },
        {
            name: 'Статус',
            selector: 'status',
            sortable: true,
            cell: (row) => deliveryText(row.status),
        },
        {
            name: 'Время',
            selector: 'createdAt',
            sortable: true,
            cell: (row) => <small>{moment(row.createdAt).format('DD.MM.YYYY kk:mm')}</small>,
        },
        {
            name: 'Клиент',
            selector: 'userId',
            cell: (row) => <Link to={'/admin/user/' + row.userId}>{row.userId}</Link>,
        },
        {
            selector: 'action',
            right: true,
            width: '100px',
            cell: (row) => (
                <div className="d-flex align-items-center">
                    <Link to={`/admin/transaction/${row.id}`} className="me-4">
                        <GrEdit size={15} color="#fff" />
                    </Link>
                    <a onClick={() => setModalDelete({isShow: !modalDelete.isShow, id: row.id})}>
                        <IoTrashOutline size={20} color="#ff5252" />
                    </a>
                </div>
            ),
        },
    ]
    const getData = (page = 1) => {
        getTransactions(page, limit)
            .then(
                (res) =>
                    res &&
                    setTransactions((prev) => ({
                        ...prev,
                        isLoaded: true,
                        items: res?.transactions?.rows,
                        pagination: res.pagination,
                    }))
            )
            .finally(() => setTransactions((prev) => ({...prev, isLoaded: true})))
    }

    const handlePageChange = (page) => {
        getData(page)
    }

    const handlePerRowsChange = async (newLimit, page) => {
        setLimit(newLimit)
        getTransactions(page, newLimit)
            .then(
                (res) =>
                    res &&
                    setTransactions((prev) => ({
                        ...prev,
                        isLoaded: true,
                        items: res?.transactions?.rows,
                        pagination: res.pagination,
                    }))
            )
            .finally(() => setTransactions((prev) => ({...prev, isLoaded: true})))
    }

    useEffect(() => {
        getData()
    }, [])

    const clickDelete = (id) => {
        deleteTransaction(id).then(() => getData())
        setModalDelete({isShow: false, id: false})
    }

    if (!transactions.isLoaded) {
        return <Loader full />
    }

    if (!transactions.items || transactions.items.length === 0) {
        return (
            <Info className="d-flex flex-column align-items-center justify-content-center account-info">
                <h3 className="mb-4">Заявок пока нет</h3>
            </Info>
        )
    }

    return (
        <section className="transactions">
            <div className="d-flex flex-row justify-content-between align-items-center mb-4">
                <h1 className="m-0">Вывод средств</h1>
            </div>
            <CustomDataTable
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
                columns={transactionColumns}
                data={transactions.items}
                pagination={transactions.pagination}
            />
            <CustomModal
                title={`Удаление ${modalDelete.id ? '#' + modalDelete.id : ''}`}
                isShow={modalDelete.isShow}
                setIsShow={(e) => setModalDelete({isShow: e, id: false})}
                footer={
                    <>
                        <Button
                            className="btn-1 me-3"
                            onClick={(e) => setModalDelete({isShow: !modalDelete.isShow, id: false})}
                        >
                            Отмена
                        </Button>
                        <Button className="btn-2" onClick={() => modalDelete.id && clickDelete(modalDelete.id)}>
                            Удалить
                        </Button>
                    </>
                }
            >
                Вы точно хотите удалить транзакцию?
            </CustomModal>
        </section>
    )
}

export default Transactions
