import React from 'react'
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from 'react-router-dom'
import AppLayout from '../layouts/AppLayout'
import Home from '../pages/Home'
import NotFound from '../pages/NotFound'
import AuthRoute from '../layouts/AuthRoute'
import AdminRounter from './AdminRouter'
import Policy from '../pages/Policy'

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<AppLayout />}>
            <Route index element={<Home />} />
            <Route path="policy" element={<Policy />} />
            <Route
                path="admin/*"
                element={
                    <AuthRoute admin>
                        <AdminRounter />
                    </AuthRoute>
                }
            />
            <Route path="*" element={<NotFound />} />
        </Route>
    )
)

const AppRouter = () => {
    return <RouterProvider router={router} />
}

export default AppRouter
