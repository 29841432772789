import React, {useCallback, useEffect} from 'react'
import {MetaTags} from 'react-meta-tags'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import LoginForm from '../components/forms/LoginForm'
import {login} from '../services/RTK/auth'

const Home = () => {
    const auth = useSelector((state) => state?.auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onSubmitLogin = useCallback((data) => {
        dispatch(login(data))
    }, [])

    useEffect(() => {
        auth.isAuth && navigate('/admin')
    }, [auth.isAuth])

    return (
        <main>
            <MetaTags>
                <title>{process.env.REACT_APP_SITE_NAME} — панель администратора</title>
            </MetaTags>
            <div className="login">
                <h2 className="text-center">Вход</h2>
                <LoginForm onSubmit={onSubmitLogin} />
            </div>
        </main>
    )
}

export default Home
