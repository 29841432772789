import React from 'react'
import {Col, Form, Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import Button from '../../UI/Button'

const OrderForm = ({onSubmit, order = {}}) => {
    const {
        register,
        formState: {errors, isValid},
        handleSubmit,
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onSubmit',
        defaultValues: {
            id: order.id ?? 0,
            total: order.total ?? '',
            point: order.point ?? '',
            status: order.status ?? 0,
        },
    })

    return (
        <Form className="profile-edit" onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col md={6}>
                    <Form.Group className="mb-4">
                        <Form.Label>Общая сумма</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="0"
                            {...register('total', {max: {value: 99999, message: 'Максимум 99999 руб'}})}
                        />
                        {errors.total && <Form.Text className="text-danger">{errors?.total?.message}</Form.Text>}
                        <Form.Text className="text-muted">Общая сумма чека</Form.Text>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-4">
                        <Form.Label>Баллы</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="0"
                            {...register('point', {max: {value: 99999, message: 'Максимум 99999 руб'}})}
                        />
                        {errors.point && <Form.Text className="text-danger">{errors?.point?.message}</Form.Text>}
                        <Form.Text className="text-muted">Баллы начисления</Form.Text>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-4">
                        <Form.Label>Статус</Form.Label>
                        <Form.Select
                            {...register('status', {required: 'Обязательное поле'})}
                            className="form-control"
                        >
                            <option value={0}>Обработка</option>
                            <option value={1}>Принято</option>
                            <option value={2}>Отменено</option>
                        </Form.Select>
                        {errors.status && <Form.Text className="text-danger">{errors?.status?.message}</Form.Text>}
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group>
                <Button type="submit" className="btn-2" disabled={!isValid}>
                    Сохранить изменения
                </Button>
            </Form.Group>
        </Form>
    )
}

export default OrderForm
